import React from "react";
import "../styles/404.scss";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/button/Button";


const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="error-page--container">
        <StaticImage
          src="../assets/images/404.png"
          alt="404 Fehler Bild"
          //   width={200}
          //   height={200}
          objectFit="cover"
          layout="fullWidth"
          className="error-page--container--image"
        />

        <div className="error-page--container--content">
          <h1>Ups, da ist etwas schief gelaufen!</h1>
          <Button>
            <Link to="/">zurück zur Startseite</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
